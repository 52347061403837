/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.hidden {
  display: none !important;
}

body
{
  font-family: 'Playfair Display', serif;
  margin: 0px;
}

code {
  white-space: pre;
  font-size: 11px;
  font-family: monospace;
  line-height: 12px;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  text-transform: lowercase !important;
  line-height: 1.4em;

  &::first-letter {
    text-transform: uppercase !important;
  }

  &.notransform {
    text-transform: none !important;
    &::first-letter {
      text-transform: none !important;
    }
  }
}

h1 {
  font-size: 64px;
  &::first-letter {
      text-transform: uppercase !important;
    }
}
h2 {
  font-size: 56px;
  &::first-letter {
      text-transform: uppercase !important;
    }
}

.clickable {
  cursor: pointer
}

.nosel
{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.content
{
  max-width: 1370px;
  margin: 0 auto;
}

header
{
  width: 100%;
  float: left;
  height: 170px;
  line-height: 170px;
  display: flex;
  z-index: 10;
}

header .logo
{
  float: left;
  z-index: 10;
  margin-left: 35px;
}

header .logo img
{
  height: 146px;
  margin-top: 24px;
}

header .menu
{
  float: left;
  flex: 1;
  text-align: center;
  margin-left: 15px;
  margin-top: 64px;


  a {
    float: left;
    font-size: 20px;
    display: inline-block;
    margin-left: 15px;
    margin-right: 20px;
    color: #000;
    text-decoration: none;
    /*height: 42px;*/
    line-height: 42px;
    border-bottom: 2px solid transparent;
    position: relative;
    &:after {
      content: " ";
      display: block;
      width: 0;
      height: 2px;
      background-color: #d1ae68;
      transition: all 0.3s;
      left: 50%;
      position: absolute;
    }
  }
  a.sel, > a:hover, .menu1 > a:hover {
    &:after {
      width: 100%;
      left:0;
    }
    //border-bottom: 2px solid #D1AE68;
  }

  a+b {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
    background-image: url(assets/img/fi_chevron-down.svg);
    transition: all 0.1s ease-in-out;
    position: absolute;
    line-height: 42px;
    top: 13px;
    right: 0px;
  }

  a b // old way

    {
    display: inline-block;
    margin-left: 5px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
    background-image: url(assets/img/fi_chevron-down.svg);
    transition: all 0.1s ease-in-out;
  }
}

header .menu div
{
  position: relative;
  display: block;
  float: left;
  cursor: pointer;
}

header .menu div .options
{
  display: none;
  position: absolute;
  top: 71px;
  margin-top: 0px;
  left: 0px;
  background-color: rgba(255,255,255,0.8);
  backdrop-filter: blur(5px);
  width: 100%;
  z-index: 9;
  border-right: 10px solid #bf9032;
  border-bottom: 10px solid #bf9032;
  box-sizing: border-box;
  padding: 25px;
  padding-top: 0px;
}
.ion-delegate-host .options {
    background-color: #fff;
    width: 100%;
    z-index: 9;
    border-right: 10px solid #bf9032;
    border-bottom: 10px solid #bf9032;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 0px;
}

header .menu div .options a, .ion-delegate-host .options a
{
  float: left;
  margin: 0px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  /*height: 42px;*/
  line-height: 42px;
}

header .menu div .options a:hover,
.ion-delegate-host .options a:hover
{
  font-weight: bold;
}

:root {
  --ion-backdrop-opacity: 0!important;
  --backdrop-opacity: 0!important;

  --swiper-pagination-color: #d1ae68;

  --swiper-pagination-bullet-horizontal-gap: 10px;
  --swiper-pagination-bullet-width: 16px;
  --swiper-pagination-bullet-height: 16px;
  --swiper-pagination-bullet-inactive-color: black;
  --swiper-pagination-bullet-inactive-opacity: 0.1;
  --swiper-pagination-bullet-active-color: #d1ae68;
  --swiper-pagination-bullet-active-opacity: 1;


}



header .user
{
  margin-left: 15px;
  margin-top: 66px;
  border-radius: 50%;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 21px;
  background-image: url(assets/img/user.svg);
  background-color: #EEDFC1;
  width: 38px;
  height: 38px;
  z-index: 1;
}

header .logout
{
  margin-left: 15px;
  margin-top: 66px;
  border-radius: 50%;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 21px;
  background-image: url(assets/img/logout.svg);
  background-color: #EEDFC1;
  width: 38px;
  height: 38px;
  z-index: 1;
}

header .mobmenu
{
  z-index: 1;
  margin-right: 15px;
  margin-top: 66px;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 21px;
  background-image: url(assets/img/menu.svg);
  width: 38px;
  height: 38px;
  display: none;
}

header .search
{
  margin-top: 66px;
  border-radius: 50%;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url(assets/img/search.svg);
  background-color: #fff;
  width: 38px;
  height: 38px;
  margin-left: 15px;
}

header .phone
{
  margin-top: 66px;
  margin-left: 15px;
  border: 1px solid #EEDFC1;
  height: 38px;
  line-height: 28px;
  border-radius: 19px;
  font-size: 24px;
  float: left;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-size: 24px;
  background-image: url(assets/img/phone.svg);
  padding-left: 42px;
  padding-right: 16px;
  transition: all 0.2s;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
  }
  &:before {
    content: " ";
    display: block;
    background-color: rgba(209,174,104,0.74);
    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: left 4px center;
    background-size: 24px;
    background-image: url(assets/img/phone.svg);
    left: 1px;
    position: absolute;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    top: 1px;
    opacity: 0;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

header .logged-in-user
{
  margin-top: 66px;
  margin-left: 15px;
  //border: 1px solid black; //#EEDFC1;
  height: 38px;
  line-height: 28px;
  border-radius: 19px;
  font-size: 18px;
  float: left;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: left 8px center;
  background-size: 22px;
  background-image: url(assets/img/user.svg);
  background-color: #ffffee;
  padding-left: 22px;//42px;
  padding-right: 16px;
  box-shadow: 0 0px 0px 1px black;
}

.mt-0
{
  margin-top: 0px !important;
}

.pt-0
{
  padding-top: 0px !important;
}

.mt-10
{
  margin-top: 10px !important;
}

.mt-20
{
  margin-top: 20px !important;
}

.mt-30
{
  margin-top: 30px !important;
}

.mt-40
{
  margin-top: 40px !important;
}

.mt-50
{
  margin-top: 50px !important;
}

.mb-10
{
  margin-bottom: 10px !important;
}

.mb-20
{
  margin-bottom: 20px !important;
}

.mb-30
{
  margin-bottom: 30px !important;
}

.mb-40
{
  margin-bottom: 40px !important;
}

.mb-50
{
  margin-bottom: 50px !important;
}

.slider
{
  float: left;
  width: 100%;
  height: 570px;
  position: relative;
  margin-bottom: 55px;
}

.slider .block
{
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 170px;
  padding-right: 170px;
}

.slider .block .left
{
  float: left;
  width: calc(50% - 15px);
  margin-right: 15px;
}

.slider .block .left img
{
  width: 100%;
  aspect-ratio: 4/3;
  float: left;
  position: relative;
  height: auto;
}

.slider .block .right
{
  margin-left: 15px;
  float: left;
  width: calc(50% - 15px);
}

.slider .block i
{
  float: left;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 20px;
}

.slider .block b
{
  float: left;
  width: 100%;
  font-size: 34px;
  margin-bottom: 20px;
}

.slider .block span
{
  float: left;
  width: 100%;
  font-size: 18px;
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 30px;
}

.slider .button
{
  font-size: 14px;
  letter-spacing: 3px;
  font-family: 'PT Sans', sans-serif;
  height: 48px;
  line-height: 48px;
  border: 1px solid #272727;
  color: #272727;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  transition: 0.2s all;
  &:hover {
    background-color: #3C3B3B;
    color: #fff;
  }
}

.slider .shop
{
  font-family: 'PT Sans', sans-serif;
  border: 1px solid #272727;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  color: #272727;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  padding-right: 14px;
  padding-left: 14px;
  letter-spacing: 3px;
  transition: all 0.2s;
  &:hover {
    background-color: #3C3B3B;
    color: #fff;
  }
}

.slider .block_text
{
  position: absolute;
  top: 40px;
  left: 40px;
  width: 590px;
  z-index: 1;
  padding-right: 15px;
}

.slider .block_text b
{
  font-weight: normal;
  font-size: 56px;
  float: left;
  width: 100%;
}

.slider .block_text span
{
  font-family: 'PT Sans', sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #272727;
  float: left;
  width: 100%;
}

.slider .block_image
{
  background-color: #EEDFC1;
  height: 570px;
  position: absolute;
  top: -30px;
  right: 0px;
  width: 1035px;
}

.slider h2,.slider h1
{
  float: left;
  width: 100%;
  font-size: 64px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.slider .block_image img
{
  height: 480px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  aspect-ratio: 16/9;
  object-fit: cover;
  image-resolution: from-image 300dpi;
  image-rendering: pixelated;
}

.slider .block_image video, .slider .block_image .video
{
  height: 480px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.slider .block_image .video {
  width:100%;
  height:0px;
  position:relative;
  padding-bottom:56.338%;
}

.slider .nav
{
  position: absolute;
  bottom: -50px;
  left: 0px;
  width:  100%;
  text-align: center;
}

.slider .nav.main
{
  bottom: -25px;
}

.slider .nav .item.active
{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #D1AE68;
  display: inline-block;
  border: 1px solid #D1AE68;
}

.slider .nav .item
{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #000;
  margin-left: 10px;
  margin-right: 10px;
}

.pick_category {
  margin: 70px auto;
  width: 100%;
  max-width: 500px;
  h2 {
    font-family: 'PT Sans', sans-serif;
    font-size: 26px;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 30px;
    text-transform: uppercase!important;
  }
  p {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;


    .button {
      font-family: 'PT Sans', sans-serif;
      border: 1px solid #272727;
      font-size: 14px;
      min-height: 48px;
      line-height: 48px;
      color: #272727;
      text-decoration: none;
      display: inline-block;
      font-weight: bold;
      padding-right: 14px;
      padding-left: 14px;
      letter-spacing: 3px;
      transition: all 0.2s;
      text-transform: uppercase;
      margin-inline: 0.4em;
      text-align: center;

      &:hover {
        background-color: #3C3B3B;
        color: #fff;
      }
    }
  }
}

.c
{
  clear: both;
}

.spacer_1
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 315px 51px;
  background-image: url(assets/img/spacer_1.png);
  float: left;
  width: 100%;
  height: 51px;
}

.spacer_2
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 315px 36px;
  background-image: url(assets/img/spacer_2.png);
  float: left;
  width: 100%;
  height: 36px;
}

.spacer_3
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1290px 36px;
  background-image: url(assets/img/spacer_3.png);
  float: left;
  width: 100%;
  height: 36px;
  margin-top: 80px;
}

.spacer_4
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 301px 23px;
  background-image: url(assets/img/spacer_4.png);
  float: left;
  width: 100%;
  height: 23px;
}

.spacer_5
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 412px 23px;
  background-image: url(assets/img/spacer_5.png);
  float: left;
  width: 100%;
  height: 23px;
}

.spacer_6
{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 422px 59px;
  background-image: url(assets/img/spacer_6.png);
  float: left;
  width: 100%;
  height: 59px;
}


.items
{
  float: left;
  width: calc(100% - 60px);
  margin-left: 30px;
  margin-right: 30px;

  .flex {
    display: flex;
    flex-wrap: wrap;
    float: left;
    width: 100%;
    &.center {
      justify-content: center;
    }
  }

  h2 {
    font-family: 'PT Sans', sans-serif;
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 9.8px;
    box-sizing: border-box;
    text-transform: uppercase!important;

    &.left {
      text-align: left;
      padding-left: 20px;
    }
  }

  .item {
    float: left;
    width: calc((100% / 4) - 30px);
    margin: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 96px;
    border: 13px solid #d1ae68;
    flex: 0 1 auto;

    &.blue {
      border-color: #E0F3F4;
    }

    &.yellow {
      border-color: #FEE7A9;
    }

    &.fuksia {
      border-color: #F9CED2;
    }

    b {
      font-size: 24px;
      font-weight: normal;
      float: right;
      width: calc(100% - 16px);
      margin-top: 4px;
      line-height: 1.35em;
      height: 2.7em;
      height: 4.1em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .desc {
      float: left;
      margin-left: 16px;
      width: calc(100% - 32px);
      margin-top: 10px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'PT Sans', sans-serif;
      height: 5em;
      max-height: 5em;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      display: none;
    }

    .bag {
      background-color: #3C3B3B;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 32px;
      background-image: url(assets/img/bag.svg);
      width: 56px;
      height: 56px;
      position: absolute;
      bottom: -13px;
      right: -13px;
      cursor: pointer;
      transition: 0.2s all;
      color:#fff;
      &:hover {
        background-color: #D1AE68;
        backdrop-filter: invert(1);
        color:#000;
      }

      .opc {
        position: absolute;
        top: 55px;
        right: 0px;
        border: 2px solid #D1AE68;
        background-color: #fff;
        z-index: 3;
        display: none;
        a {
          float: left;
          font-family: 'PT Sans', sans-serif;
          color: #404245;
          font-size: 15px;
          white-space: nowrap;
          height: 32px;
          line-height: 32px;
          padding-left: 10px;
          padding-right: 10px;
          border-bottom: 1px solid #E8E8E8;
          color: #000;
          text-decoration: none;
          text-align: left;

          &:last-of-type {
            border-bottom: 0px;
          }

          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    .img {
      width: calc(100% - 3px);
      margin-right: -13px;
      float: right;
      aspect-ratio: 4 / 3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .new {
      font-family: 'PT Sans', sans-serif;
      background-color: #FEE7A9;
      border-radius: 50%;
      width: 72px;
      height: 72px;
      text-align: center;
      line-height: 72px;
      foont-size: 13px;
      position: absolute;
      right: 0px;
      top: -36px;
      font-weight: bold;
    }

    .price {
      position: absolute;
      bottom: 13px;
      left: 16px;
      font-size: 24px;
      color: #272727;

      span {
        font-size: 56px;
      }
    }

    .discount {
      font-family: 'PT Sans', sans-serif;
      position: absolute;
      bottom: 65px;
      left: 16px;
      font-size: 15px;
      color: #272727;
      text-decoration: line-through;
    }


    .vertical {
      font-family: 'PT Sans', sans-serif;
      color: #3C3B3B;
      font-size: 12px;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      transform: scale(-1);
      letter-spacing: 3px;
      position: absolute;
      top: 0px;
      left: 0px;
      text-transform: uppercase;
    }
  }
}


.aktualno
{
  float: left;
  width: 100%;
  background-color: #FBDB99;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.aktualno.white
{
  background-color: #fff;
}

.aktualno.center
{
  text-align: center;
}

.aktualno h2
{
  font-family: 'PT Sans', sans-serif;
  font-size: 26px;
  float: left;
  width: 100%;
  text-align: center;
  letter-spacing: 6px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase!important;
}

.aktualno .item
{
  float: left;
  width: calc((100% / 3) - 30px);
  margin: 15px;
}

.aktualno .item.center
{
  float: none;
  display: inline-block;
}

.aktualno .item b
{
  text-align: left;
  float: left;
  font-weight: normal;
  font-size: 28px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aktualno .item span
{
  float: left;
  font-size: 18px;
  width: 100%;
  text-align: left;
  font-family: 'PT Sans', sans-serif;
}

.aktualno .item i
{
  text-align: left;
  float: left;
  font-size: 12px;
  width: 100%;
  font-style: normal;
  margin-top: 3px;
  font-family: 'PT Sans', sans-serif;
}

.aktualno .item .img
{
  width: 100%;
  aspect-ratio: 4 / 3;
}

.aktualno .item .img img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zaupamo
{
  float: left;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.zaupamo h2
{
  font-family: 'PT Sans', sans-serif;
  font-size: 26px;
  float: left;
  width: 100%;
  text-align: center;
  letter-spacing: 6px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase!important;
}

.zaupamo .item
{
  display: inline-block;
  width: 160px;
  box-sizing: border-box;
  margin: 20px;
}

.zaupamo .item img
{
  border: 13px solid #C8DCD1;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.zaupamo .item b
{
  width: 100%;
  float: left;
  font-size: 20px;
  text-align: left;
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 10px;
}

.zaupamo .item span
{
  white-space: normal;
  font-family: 'PT Sans', sans-serif;
  width: 100%;
  float: left;
  font-size: 13px;
  text-align: left;
}

footer
{
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 100px;

  .copyright,.poweredby {
    font-family: 'PT Sans', sans-serif;
    color: #565655;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-bottom: 15px;
    span {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .poweredby {
    color: #dedede;
    a {
      color: #cecece;
      text-decoration: none;
    }
  }


  .phone {
    font-size: 48px;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .menu {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    a {
      font-size: 20px;
      margin-left: 15px;
      margin-right: 30px;
      text-decoration: none;
      color: #000;
    }
  }


  .social {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transition: 0.2s all;
    &:hover {
      backdrop-filter: saturate(1.5);
      background-color: #d1ae68;
    }

    &.facebook {
      background-image: url(assets/img/social_face.png);
    }

    &.tiktok {
      background-image: url(assets/img/social_tik.png);
    }

    &.instagram {
      background-image: url(assets/img/social_insta.png);
    }
  }
}


.kategorija
{
  float: left;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.kategorija h2,.kategorija h1
{
  font-size: 64px;
  float: left;
  width: 100%;
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
  padding: 0px;
  flex: none;
}

.kategorija .item
{
  float: left;
  margin: 30px;
  aspect-ratio: 3/4;
  width: calc((100% / 4) - 60px);
}

.kategorija b
{
  float: left;
  font-size: 24px;
  font-weight: normal;
  background-repeat: no-repeat;
  background-position: right top 12px;
  background-size: 28px 16px;
  background-image: url(assets/img/arrow_1.svg);
  padding-right: 42px;
  box-sizing: border-box;
}

.kategorija .item img
{
  border-right: 13px solid #EEDFC1;
  border-bottom: 13px solid #EEDFC1;
  width: 100%;
    aspect-ratio: 3/4;
      object-fit: cover;
}

.midblock
{
  float: left;
  width: calc(100% - 30px);
  border-top: 30px solid #E0F3F4;
  border-right: 30px solid #E0F3F4;
  border-bottom: 30px solid #E0F3F4;
  position: relative;
  display: flex;
  align-items: center;
}

.midblock .priceblock,.bigblock .priceblock
{
  display: inline-block;
  position: relative;
}

.midblock .price,.bigblock .price
{
  float: left;
  font-size: 24px;
  color:  #272727;
  height: 56px;
  line-height: 56px;
}

.midblock .price i,.bigblock .price i
{
  font-size: 56px;
  font-style: normal;
}

.midblock .discount,.bigblock .discount
{
  font-family: 'PT Sans', sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 15px;
  color:  #272727;
  text-decoration: line-through;
}

.midblock.full
{
  text-align: center;
  border-left: 30px solid #E0F3F4;
  box-sizing: border-box;
  padding: 50px;
}

.midblock.full .text b
{
  font-family: 'PT Sans', sans-serif;
  font-size: 25px;
}

.midblock img
{
  float: left;
  width: 440px;
}

.midblock .text
{
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
  float: left;
  flex: 1;
}

.midblock .text b
{
  font-size: 28px;
  float: left;
  width: 100%;
  font-weight: normal;
}

.midblock .text span
{
  font-family: 'PT Sans', sans-serif;
  margin-top: 30px;
  font-size: 18px;
  float: left;
  width: 100%;
}

.midblock.full .text span
{
  font-size: 28px;
}

.midblock .shop,.bigblock .shop
{
  font-family: 'PT Sans', sans-serif;
  border: 1px solid #272727;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  color: #272727;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  padding-right: 14px;
  letter-spacing: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    border-color: #d1ae68;
    & b {
      background-color: #D1AE68;
      color: #000;
    }
  }
}

.midblock .shop b,.bigblock .shop b
{
  background-color: #3C3B3B;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url(assets/img/bag.svg);
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 14px;
  transition: 0.2s all;
}

.midblock .mobileshop,.bigblock .mobileshop {
  display: none!important;
  font-family: 'PT Sans', sans-serif;
  border: 1px solid #272727;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  color: #272727;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  padding-right: 14px;
  letter-spacing: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;

  ion-fab-button {
    --background: #3C3B3B;
    --background-activated: #d1ae68;
    --background-hover: #d1ae68;
    --border-radius: 0;
    --box-shadow: none;
    --color: white;
    --color-activated: black;
    --color-hover: black;
    --transition: 0.1s all;
    width:48px;
    height:48px;
  }
  span {
    padding-left:62px;
    font-size: 16px!important;
    margin-top: 0!important;
  }

  .fab-list-active {
    left: 0;
    border: 2px solid #d1ae68;
    min-height: auto;
    margin: 42px 0;
  }

  .ion-color {
    .button-native {
      background-color: #fff;
      color: #000;
      border-bottom: 1px solid silver;

      span {
        text-align: left;
        padding: 1em;
      }
    }
  }

  .fab-button-in-list {
    width: 150px;
    margin: 0;
    --ion-color-light: #fff;
    border-bottom: 1px solid silver;
  }

  &:hover {
    border-color: #d1ae68;

    & b {
      background-color: #D1AE68;
      color: #000;
    }
  }
}

.midblock .vertical
{
  font-family: 'PT Sans', sans-serif;
  color: #3C3B3B;
  font-size: 12px;
  writing-mode: vertical-rl;
    text-orientation: sideways;
    transform:scale(-1);
    letter-spacing: 3px;
    position: absolute;
    top: 10px;
    left: 450px;
}

.smallblock
{
  float: left;
  width: 100%;
  height: 120px;
  margin-top: -35px;
  z-index: 0;
  position: relative;
}

.smallblock.full
{
  height: 280px;
  line-height: 280px;
  margin-bottom: 30px;
}

.smallblock.full h1
{
  float: left;
  font-size: 64px;
  width: 100%;
  text-align: center;
  margin: 0px;
  padding: 0px;
  display: block;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.smallblock .info
{
  float: left;
  margin-left: 150px;
  width: calc(100% - 150px);
  height: 42px;
  line-height: 42px;
  margin-top: 50px;
}

.smallblock .info .date
{
  float: left;
  font-size: 28px;
}

.smallblock .info .circle
{
  margin-left: 50px;
  float: left;
  height: 42px;
  line-height: 42px;
}

.smallblock .info b
{
  width: 14px;
  height: 14px;
  border: 1px solid #000;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 13px;
  display: inline-block;
}

.smallblock .info b.sel
{
  border: 1px solid #D1AE68;
  background-color: #D1AE68;
}

.smallblock .info b.checked
{
  border: 1px solid #D1AE68;
  background-color: #D1AE68;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px;
  background-image: url(assets/img/check.svg);
}

.smallblock .info .name
{
  margin-left: 30px;
  float: left;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
}

.bigblock
{
  float: left;
  width: 100%;
  min-height: 624px;
  margin-top: -35px;
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.bigblock .clock
{
  float: right;
  margin-left: 30px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32px;
  background-image: url(assets/img/clock.svg);
  padding-left: 38px;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: 'PT Sans', sans-serif;
  margin-top: 20px;
}

.bigblock .vertical
{
  font-family: 'PT Sans', sans-serif;
  color: #3C3B3B;
  font-size: 12px;
  writing-mode: vertical-rl;
    text-orientation: sideways;
    transform:scale(-1);
    letter-spacing: 3px;
    position: absolute;
    top: 110px;
    left: 80px;
}

.bigblock .down
{
    position: absolute;
    bottom: 40px;
    left: 120px;
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px;
  background-image: url(assets/img/arrow_2.svg);
}

.bigblock.black
{
  background-color: #272727;
  color: #D1AE68;
}

.bigblock.yellow,.smallblock.yellow
{
  background-color: #EEDFC1;
}

.bigblock.yellow_dark
{
  background-color: #FBDB99;
}

.bigblock.blue
{
  background-color: #E0F3F4;
}

.bigblock img
{
  aspect-ratio: 4/3;
  //position: absolute;
  bottom: 0px;
  right: 0px;
  height: 514px;
  //max-height: 38vh;
}

.bigblock .text
{
  //position: absolute;
  width: 50%;
  font-size: 27px;
  bottom: 20px;
  left: 0px;
  box-sizing: border-box;
  aspect-ratio: 4 / 3;
  padding-left: 130px;
  padding-right: 100px;
  font-size: clamp(1.25rem,1.95vmin,1.63rem);
  padding-top:80px;
  padding-bottom:100px;
  align-self: baseline;
}

.bigblock .text h1 {
  font-size: clamp(1.5rem, 5.6vh, 4rem);
  &.medium {
    font-size: clamp(1.5rem, 5vh, 4rem);
  }
  &.long {
    font-size: clamp(1.5rem, 4.3vh, 4rem);
  }
}

.bigblock .text h2
{
  font-size: 64px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
  font-size: clamp(1.5rem, 4.3vh, 4rem);
}

.content .textblock
{
  font-size: 27px;
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 150px;
  padding-right: 150px;
}

.content .textblock ul
{
  padding-left: 40%;
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin-top: 50px;
}

.content .textblock ul li
{
  margin-top: 10px;
  list-style-type: none;
  float:left;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 28px 16px;
  background-image: url(assets/img/arrow_1.svg);
  padding-left: 42px;
  font-size: 24px;
}

.chip
{
  float: left;
  width: 100%;
  text-align: center;
}

.chip.left
{
  margin-left: 45px;
  margin-right: 45px;
  width: calc(100% - 90px);
  text-align: left;
}

.chip.left a
{
  margin-left: 0px;
}

.chip a
{
  display: inline-block;
  border: 1px dashed #272727;
  height: 42px;
  line-height: 42px;
  border-radius: 21px;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  color: #252729;
  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    border-style: solid;
  }
}

.chip a.sel
{
  color: #fff;
  background-color: #252729;
}

.clanek
{
  float: left;
  width: 100%;
  position: relative;
  margin-top: -35px;
  padding-top: 90px;

  .bg {
    background-color: #FEE7A9;
    height: 480px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }

  h2,
  h1 {
    font-size: 56px;
    float: left;
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
    padding-inline: 1em;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    float: left;
    margin: 0px;
    margin-left: 350px;
    margin-right: 350px;
    padding-top: 20px;
    padding-bottom: 5px;
    clear: both;
    z-index: 1;
    position: relative;
    width: calc(100% - 700px);
  }

  p {

    float: left;
    margin-left: 350px;
    margin-right: 350px;
    font-size: 24px;
    width: calc(100% - 700px);
    z-index: 1;
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ul {
    clear: both;
    float: left;
  }
  > ul {

    float: left;
    margin-left: 350px;
    margin-right: 350px;
    width: calc(100% - 700px);
    z-index: 1;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'PT Sans', sans-serif;

  }


  li {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .sub {
    font-family: 'PT Sans', sans-serif;
    font-size: 12px;
    color: #3C3B3B;
    float: left;
    width: 100%;
    text-align: center;
    z-index: 1;
    position: relative;
    text-transform: uppercase;
  }

  p.small,div.small p{
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;
  }

  div.p {
    float: left;
    margin-left: 350px;
    margin-right: 350px;
    width: calc(100% - 700px);
    z-index: 1;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      font-size: 18px;
      margin: 0;
      width: auto;
    }
  }

  small {
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;
  }

  .bigimage {
    margin-left: 250px;
    margin-right: 250px;
    width: calc(100% - 500px);
    float: left;
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
  }

  .bigimage img {
    float: left;
    width: 100%;
    aspect-ratio: 4/3;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }

  .smallimage {
    margin-left: 250px;
    margin-right: 250px;
    width: calc(100% - 500px);
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
    float: left;
  }

  .smallimage img {
    float: left;
    width: 50%;
    aspect-ratio: 4/3;
    box-sizing: border-box;
    padding: 10px;
  }

  .small {
    > ul {
      float: left;
      margin-left: 350px;
      margin-right: 350px;
      width: calc(100% - 700px);
      z-index: 1;
      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: 'PT Sans', sans-serif;

    }

  }
}

.options
{
  float: left;
  width: 100%;
}


.saloni
{
  z-index: 0;
  float: left;
  width: 100%;
  position: relative;
  padding-top: 90px;
  margin-top: -35px;
}

.saloni .buttons
{
  float: left;
  display: flex;
  width: calc(100% - 300px);
  margin-left: 150px;
  margin-right: 150px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}

.saloni .button_text
{
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  height: 48px;
  line-height: 48px;
  color: #272727;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  flex: 1;
  font-size: 15px;
  &:empty {
    padding: 0;
  }
}

.saloni .button_1
{
  letter-spacing: 3px;
  font-family: 'PT Sans', sans-serif;
  height: 48px;
  line-height: 48px;
  border: 1px solid #272727;
  color: #272727;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  float: left;
  font-size: 14px;
}

ion-button.button_1 {
  letter-spacing: 3px;
  font-family: 'PT Sans', sans-serif;
  height: 48px;
  line-height: 48px;
  border: 1px solid #272727;
  color: #272727;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 14px;

  --background: transparent; /* Ionic specific: Sets the background to transparent */
  --background-activated: grey; /* Optional: Background color when activated */
  --background-focused: grey; /* Optional: Background color when focused */
  --border-radius: 0; /* Ionic specific: Sets the border radius */
  --box-shadow: none; /* Ionic specific: Removes box shadow */
  /* ... Additional Ionic specific styles as needed ... */
}

.saloni .button_2.sel
{
  background-color: #272727;
  transition: all 0.15s;
  &:hover {
    background-color: #d1ae68;
    color: #000
  }
}

.saloni .button_2
{
  font-size: 14px;
  letter-spacing: 3px;
  font-family: 'PT Sans', sans-serif;
  height: 48px;
  line-height: 48px;
  border: 1px solid #C7C7C7;
  background-color: #C7C7C7;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-weight: bold;
  float: right;
}

.saloni .bg
{
  height: 440px;
  width: 100%;
  background-color: #EEDFC1;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.saloni h1
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 56px;
  color: #000;
  text-align: center;
  z-index: 1;
  position: relative;
}

.saloni h1.left
{
  text-align: left;
  font-size: 34px;
  width: calc(100% - 300px);
    margin-left: 150px;
    margin-right: 150px;
}

.saloni .map
{
  width: calc(100% - 300px);
  z-index: 1;
  position: relative;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 40px;
  float: left;
}

.saloni .map.fullmap
{
  margin-top: 30px;
}

.saloni .map img
{
  width: 100%;
  float: left;
}

.saloni span
{
  float: left;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  letter-spacing: 3px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.saloni .block
{
  float: left;
  width: calc(100% - 300px);
  border: 1px dashed #999999;
  padding: 30px;
  box-sizing: border-box;
  margin-left: 150px;
  margin-right: 150px;

  &.sel {
    border: 2px solid black;
  }
}

.saloni .block.white
{
  background-color: #F5F5F5;
  border: 0px;
  padding-bottom: 0px;
}

.saloni .block.white b
{
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.saloni .block.white div
{
  font-size: 18px;
  margin-bottom: 30px;
  font-family: 'PT Sans', sans-serif;
}

.saloni .block.noborder
{
  border: 0px;
  padding: 0px;
}

.saloni .block .left
{
  float: left;
  width: 50%;
}

.saloni .block .left b
{
  float: left;
  width: 100%;
  font-size: 24px;
  color: #111827;
  margin-bottom: 15px;
}

.saloni .block .left i
{
  float: left;
  font-size: 24px;
  color: #272727;
  margin-bottom: 15px;
  font-style: normal;
  background-color: #D1AE68;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  padding-left: 14px;
  padding-right: 14px;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3px;
}

.saloni .block .left .info
{
  color: #272727;
  text-decoration: underline;
  float: left;
  width: 100%;
  font-size: 15px;
  font-family: 'PT Sans', sans-serif;
  padding-left: 32px;
  line-height: 42px;

  &.pin,&.phone,&.mail {
    background-repeat: no-repeat;
    background-position: left center;
    a {
      color: inherit;
    }
  }

  &.pin {
    background-size: 21px;
    background-image: url(assets/img/info_pin.svg);

  }

  &.phone {
    background-size: 24px;
    background-image: url(assets/img/info_phone.svg);
  }

  &.mail {
    background-size: 24px;
    background-image: url(assets/img/info_mail.svg);
  }
}



.saloni .block .right
{
  float: left;
  width: 50%;
  background-color: #EEDFC1;
  padding: 30px;
  box-sizing: border-box;
}

.saloni .block .right span
{
  float: left;
  width: 100%;
  font-size: 12px;
  font-family: 'PT Sans', sans-serif;
  color: #272727;
  text-align: left;
}

.saloni .block .right span:last-of-type
{
  margin-top: 20px;
}

.saloni .block .right div
{
  float: left;
  width: 100%;
  font-size: 24px;
  color: #272727;
}

.saloni .warning
{
  float: left;
  width: calc(100% - 300px);
  background-color: #FAEBEB;
  padding: 20px;
  padding-left: 48px;
  box-sizing: border-box;
  font-size: 15px;
  color: #272727;
  font-family: 'PT Sans', sans-serif;
  margin-left: 150px;
  margin-right: 150px;
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 24px;
  background-image: url(assets/img/warning.svg);
  border-left: 2px solid #EF4444;
}

.saloni .agenda
{
  float: right;
  background-color: #EEDFC1;
  width: 320px;
  padding: 24px;
  box-sizing: border-box;
  padding-top: 18px;
  padding-bottom: 18px;
}

.saloni .agenda div
{
  float: left;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  line-height: 42px;
  font-size: 12px;
}

.saloni .agenda div b
{
  float: right;
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  font-weight: normal;
}

.rotate180
{
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -o-transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  transform:rotate(180deg);
  transition: .2s all
}

.rotate90
{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: .2s all;
}

.rotate45
{
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: .2s all;
}

.code
{
  float: left;
  width: 100%;
  position: relative;
  padding-top: 90px;
  margin-top: -35px;
  text-align: center;
}

.code h1
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 56px;
  color: #000;
  text-align: center;
  z-index: 1;
  position: relative;
}

.code b
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 25px;
  color: #000;
  text-align: center;
  z-index: 1;
  position: relative;
  font-weight: normal;
  margin-bottom: 40px;
}

.code span
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  color: #000;
  text-align: center;
  z-index: 1;
  position: relative;
  font-family: 'PT Sans', sans-serif;
  letter-spacing: 3px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.code img
{
  margin: 0 auto;
  width: 250px;
  aspect-ratio: 1;
  z-index: 1;
  position: relative;
}

.code .bg
{
  height: 55%;
  width: 100%;
  background-color: #EEDFC1;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.code .buttons
{
  display: inline-block;
}

.code .buttons .button_1
{
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  background-color: #272727;
  border: 1px solid #272727;
  color: #fff;
  letter-spacing: 3px;
  padding-left: 18px;
  padding-right: 18px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.code .buttons .button_2
{
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  border: 1px solid #272727;
  color: #272727;
  letter-spacing: 3px;
  padding-left: 18px;
  padding-right: 18px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

[name=crescent] {
  vertical-align: middle;
}

.prijava
{
  float: left;
  width: 100%;
  position: relative;
  padding-top: 90px;
  margin-top: -35px;
  text-align: center;

  h1
  {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 64px;
    color: #000;
    text-align: center;
    z-index: 1;
    position: relative;
  }

  b
  {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 25px;
    color: #000;
    text-align: center;
    z-index: 1;
    position: relative;
    font-weight: normal;
    margin-bottom: 40px;
  }

  span
  {
    box-sizing: border-box;
    display: inline-block;
    max-width: 420px;
    margin: 0px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    color: #000;
    text-align: center;
    z-index: 1;
    position: relative;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 3px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  img
  {
    margin: 0 auto;
    width: 250px;
    aspect-ratio: 1;
    z-index: 1;
    position: relative;
  }

  .buttons
  {
    display: inline-block;


    .button_1
    {
      height: 48px;
      line-height: 48px;
      background-color: #272727;
      border: 1px solid #272727;
      color: #fff;
      letter-spacing: 3px;
      padding-left: 18px;
      padding-right: 18px;
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
    }

    .button_2
    {
      height: 48px;
      line-height: 48px;
      border: 1px solid #272727;
      color: #272727;
      letter-spacing: 3px;
      padding-left: 18px;
      padding-right: 18px;
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
    }

    .button_3
    {
      height: 48px;
      line-height: 48px;
      border: 1px solid #3A559F;
      background-color: #3A559F;
      color: #fff;
      letter-spacing: 3px;
      padding-left: 48px;
      padding-right: 18px;
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: left 12px center;
      background-size: 24px;
      background-image: url(assets/img/facebook.svg);
      .spinner-crescent {
        vertical-align: middle;
        top: -2px;
        color: white;
      }
    }
  }


  .inputs
  {
    display: inline-block;
    max-width: 420px;
    box-sizing: border-box;
    //padding-left: 15px;
    //padding-right: 15px;
    min-width: 290px;

    .input-wrapper.sc-ion-input-md {
      background: white!important;
      .native-wrapper.sc-ion-input-md {
        background: var(--background);
        margin-top:0.5em;
        .native-input.sc-ion-input-md {
          padding-inline: 15px;
        }
      }
      .label-text-wrapper.sc-ion-input-md {
        transform: none;
      }
    }

    .invalid-feedback {
      margin-bottom:30px;
    }
  }

  .inputs.left
  {
    float: left;
    display: block;
    margin-left: 30px;
  }

  label
  {
    float: left;
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
    background-color: white;
  }

  input,select
  {
    display: inline-block;
    width: 100%;
    float: left;
    background-color: #F5F5F5;
    height: 56px;
    line-height: 56px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0px;
    border-bottom: 1px solid #B9B9BB;
    outline: 0px;
    margin: 0px;
    font-family: 'PT Sans', sans-serif;
    box-sizing: border-box;
  }



  select
  {
    padding-top: 15px;
  }

  input[type="radio"]
  {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    accent-color: #D1AE68;
    border: 0px;
  }

  .inputs .radio
  {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    float: left;
    line-height: 24px;
    vertical-align: middle;
    margin-right: 15px;
  }

  .day label,.month label,.year label
  {
    width: calc(100% - 20px);
    position: absolute;
    top: 10px;
    left: 20px;
    font-weight: bold;
    font-size:10px;
    letter-spacing: 1px;
    font-color: #272727;
  }

  .day
  {
    width: calc((100% / 3) - 10px);
    margin-right: 10px;
    float: left;
    position: relative;
  }

  .month
  {
    width: calc(100% / 3);
    float: left;
    position: relative;
  }

  .year
  {
    width: calc((100% / 3) - 10px);
    margin-left: 10px;
    float: left;
    position: relative;
  }

  .inputs a
  {
    float: right;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    text-decoration: underline;
    color: #000;
    margin-top: 20px;
  }

}



.prijava-input {
  --background: #F5F5F5;
  //--padding-start: 15px;
  //--padding-end: 15px;
  --border-color: transparent;
  --border-style: none;
  --border-width: 0;
  --border-radius: 0;
  --height: 56px;
  --color: #000; /* Set the text color if needed */
  --placeholder-color: #000; /* Set placeholder text color if needed */
  font-family: 'PT Sans', sans-serif;
  box-sizing: border-box;
}

.input-bottom-border {
  --padding-start: 15px;
  --padding-end: 15px;
  --border-style: none;
  --border-width: 0;
  --background: transparent;
  --color: #000; /* Text color */
  --placeholder-color: #000; /* Placeholder text color */
  border-bottom: 1px solid #B9B9BB; /* This adds the bottom border */
}

.searchbar
{
  width: 100%;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  border: 1px solid #000;
  float: left;
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 32px;
  background-image: url(assets/img/search.svg);
  position: relative;
}

.searchbar input
{
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 48px;
  height: 100%;
  width: calc(100% - 48px);
  outline: 0px;
  padding: 0px;
  margin: 0px;
  display: block;
  border: 0px;
  padding-left: 0px;
  font-size: 15px;
  color: #565655;
  padding-right: 32px;
  box-sizing: border-box;
}

.calendar
{
  position: relative;
  float: left;
  width: calc(100% - 300px);
  margin-left: 150px;
  margin-right: 150px;
}

.calendarblock.dark div,.calendarblock.white div
{
  float: left;
  width: 40%;
  text-align: left;
  margin: 20px;
  padding-left: 32px;
  background-repeat: no-repeat;
    background-position: left top;
    background-size: 21px;
    background-image: url(assets/img/info_pin.svg);
}

.calendarblock.dark div.pin,.calendarblock.white div.pin
{
  background-repeat: no-repeat;
    background-position: left top;
    background-size: 21px;
    background-image: url(assets/img/info_pin.svg);
}

.calendarblock.dark div.calend
{
  background-repeat: no-repeat;
    background-position: left top;
    background-size: 21px;
    background-image: url(assets/img/calendar.svg);
}

.calendarblock.white div.calend
{
  background-repeat: no-repeat;
    background-position: left 10px top;
    background-size: 36px;
    background-image: url(assets/img/calendar.svg);
    padding-left: 70px;
}

.calendarblock.dark span,.calendarblock.white span
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: left;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
}

.calendarblock.dark b,.calendarblock.white b
{
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: left;
  font-size: 20px;
  font-weight: normal;
}

.calendarblock.dark i,.calendarblock.white i
{
  transition: all 0.1s ease-in-out;
  width: 28px;
  height: 28px;
  margin-top: 32px;
  margin-right: 28px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px;
    background-image: url(assets/img/down_white.svg);
}

.calendar .coupon
{
  background-color: #EEDFC1;
  height: 128px;
  width: calc(50% - 10px);
  float: left;
  background-repeat: no-repeat;
    background-position: left 25px top 25px;;
    background-size: 48px;
    background-image: url(assets/img/discount.svg);
    padding: 25px;
    padding-left: 90px;
    box-sizing: border-box;
}

.coupon.first
{
  margin-right: 10px;
}

.coupon.second
{
  margin-left: 10px;
}

.calendar .coupon i,.calendar .coupon b,.calendar .coupon span
{
  float: left;
  width: 100%;
  text-align: left;
  font-weight: normal;
  font-style: normal;
}

.calendar .coupon span
{
  font-size: 12px;
  font-family: 'PT Sans', sans-serif;
}

.calendar .coupon b
{
  font-size: 20px;
}

.calendar .coupon i
{
  margin-top: 20px;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: 'PT Sans', sans-serif;
}

.calendar h1
{
  font-size: 64px;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  float: left;
  width: 100%;
  text-align: left;
}

.calendar h2
{
  font-family: 'PT Sans', sans-serif;
  font-size: 25px;
  letter-spacing: 9.8px;
  font-weight: normal;
  text-transform: uppercase!important;
}


.calendar .calendarblock
{
  float: left;
  width: 100%;
  box-sizing: border-box;

  &.white {
    border: 2px solid #565655;
    color: #000;
    position: relative;
    height: 100px;
  }

  &.dark {
    cursor: pointer;
    border: 2px solid #3C3B3B;
    background-color: #3C3B3B;
    color: #fff;
    position: relative;
    height: 100px;
  }

  &.yellow {
    border: 2px solid #FEE7A9;
  }

  &.fuksia {
    border: 2px solid #F9CED2;
  }

  &.blue {
    border: 2px solid #E0F3F4;
  }
}

.calendar .calendartitle
{
  font-family: 'PT Sans', sans-serif;
  letter-spacing: 3px;
  color: #3C3B3B;
  font-size: 12px;
  float: left;
  width: 100%;
}

.calendar .calendartitle b
{
  font-size: 15px;
  float: right;
}

.calendar .calendarblock .header
{
  float: left;
  width: 100%;
  //height: 72px;
  position: relative;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.calendar .calendarblock .header .close
{
  background-color: #E8E8E8;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-image: url(assets/img/close.svg);
}

.calendar .calendarblock .header img
{
  float: left;
  width: 56px;
  aspect-ratio: 1;
  margin: 8px;
  object-fit: cover;
}

.calendar .calendarblock .header .circle
{
  float: right;
  width: 16px;
  height: 16px;
  margin-top: 28px;
  border-radius: 50%;
}

.calendar .calendarblock .header .circle.green
{
  background-color: #61E823;
}

.calendar .calendarblock .header .circle.red
{
  background-color: #EE3526;
}

.calendar .calendarblock .header .drop
{
  transition: all 0.1s ease-in-out;
  float: right;
  width: 24px;
  height: 24px;
  margin-top: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url(assets/img/fi_chevron-down.svg);
  margin-right: 40px;
  margin-left: 15px;
}

.calendar .calendarblock .header .info
{
  float: left;
  //margin-left: 10px;
  padding-top: 10px;
  font-size: 1.2em;
  margin-bottom: 1em;

  b {
    //float: left;
    //width: 100%;
    font-size: 20px;
  }

  i {
    margin-top: 5px;
    float: left;
    width: 100%;
    font-size: 12px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    background-image: url(assets/img/clock.svg);
    padding-left: 21px;
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
  }
}


.calendar .calendarblock .lines,.lines
{
  float: left;
  width: 100%;
}

.calendar .calendarblock .line
{
  float: left;
  //width: 100%;
  height: 48px;
  line-height: 48px;
  position: relative;
  background-color: #fff;
  //border-bottom: 1px solid #E8E8E8;
  overflow: hidden;
  box-shadow: inset -1px -1px 0px 0px #E8E8E8;
  &:nth-of-type(even) {
    background-color: #F0F0F0;
  }
  &.sel {
    background-color: #d9bb93!important;
    box-shadow: 0px 1px 0px 1px #d9bb93;
    //flex-basis: 99.95%;
  }
}

.calendar {

  .other-combinations {
    clear: both;
    width: 100%;
    height: auto;
    margin-bottom: 0px;

    .other-combination {
      min-width: 360px;
      max-width: 100%;
      border: 2px solid #d9bb93;

      .col--main {
        span {
          display: inline;
        }

        img {
          display: inline;
        }
      }
    }

  }

  .calendarblock {
    .app-slots {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.2px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      &:after {
        content: "";
        display: block;
        flex-grow: 9999;
        flex: auto;
      }
    }
    .line {
      min-height: 48px;
      height: auto;
      transition: all 0.2s ease-out;
      width: 18%;
      //outline: 1px solid #fff;
      justify-content: center;
      width: 100%;
      text-align: center;

      &.collapsed {
        transform: scaleY(0) translateY(-100%);
        height: 1px;
        min-height: 0;
        width: 0;
        flex-basis: 0;
        display: none;
      }

      & + .expand {
        display: none;
        color:#3C3B3B;
        img, svg {
          display: inline-block;
          top: 6px;
          position: relative;
          color: #e8e8e8;
          background: white;
          width: 24px;
          &:hover {
            color: #3C3B3B;
          }
        }
      }

      &.collapsed + .expand {
        display: block;
        text-align: center;
        line-height: 20px;
        background-color: #F5F5F5;

        width: 80px;
        float: left;
        margin-inline: -40px;
        left:100%;
        z-index: 2;
        height:48px;
        line-height:48px;
        background-color: transparent;
        opacity: 0;
        transition: all 0.2s ease-out;
        &:hover {
          opacity: 1;
        }
      }
      &.collapsed:hover + .expand {
        opacity: 1;
      }

    }
    .expand + .collapsed + .expand {
      display:none;
    }


    .bigline
    {
      float: left;
      width: 100%;
      line-height: 48px;
      position: relative;
      background-color: #F5F5F5;
      overflow: hidden;
      text-align: center;
      padding: 24px;
      box-sizing: border-box;
    }

    .bigline b
    {
      font-family: 'PT Sans', sans-serif;
      font-size: 18px;
    }

    .bigline span
    {
      font-family: 'PT Sans', sans-serif;
      font-size: 15px;
      letter-spacing: 1px;
    }

    .line:last-of-type
    {
      border-bottom: 0px;
    }

    .line b
    {
      height: 48px;
      line-height: 48px;
      font-size: 15px;
      font-family: 'PT Sans', sans-serif;
      padding-left: 15px;
      padding-right: 15px;


    }

    .line span.lastMinute {
      display: inline-block;
      background-image: url(/assets/icons/discount2.svg);
      background-position: left 30%;
      background-size: 24px 24px;
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
      vertical-align: middle;
      float: none;
    }

  }
}


.calendar .calendarblock .line div
{
  border-radius: 14px;
  height: 28px;
  line-height: 26px;
  font-size: 15px;
  font-family: 'PT Sans', sans-serif;
  border: 1px solid transparent;
  float: left;
  box-sizing: border-box;
  font-weight: bold;
  padding-right: 0;
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  margin:0;
  span {
    font-size: inherit;
    letter-spacing: normal;
    line-height: 48px;
    display: none;
  }
  &.price {
    position: absolute;
    top: 0;
  }
}

.calendar .calendarblock .line div.sel
{
  border: 1px solid #57CA22;
  background-color: #fff;
}

.calendar .calendarblock .line div img
{
  float: left;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  margin: 6px;
  //border-radius: 50%;
  display: none;
}

.saloni .calendar .warning
{
  float: left;
  width: 100%;
  background-color: #FAEBEB;
  padding: 20px;
  padding-left: 48px;
  box-sizing: border-box;
  font-size: 15px;
  color: #272727;
  font-family: 'PT Sans', sans-serif;
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 24px;
  background-image: url(assets/img/warning.svg);
  border-left: 2px solid #EF4444;
  box-sizing: border-box;
  margin: 0px;
}

.total
{
  float: left;
  border-top: 1px solid #000;
  width: 100%;
  font-size: 15px;
  padding-top: 30px;
  position: relative;
  height: 80px;
}

.total b
{
  font-family: 'PT Sans', sans-serif;
  float: left;
}

.calendar .total .discount,.cart .total .discount
{
  position: absolute;
  right: 0px;
  top: 70px;
  font-family: 'PT Sans', sans-serif;
  font-size: 15px;
  text-decoration: none;
}

.calendar .total .price,.cart .total .price
{
  //position: absolute;
  //right: 0px;
  //top: 12px;
  font-size: 16px;
}

.calendar .total .duration, .cart .total .duration {
  //position: absolute;
  //right: 140px;
  //top: 18px;
  font-size: 16px;
  color: #d1ae68;
}

.calendar .total .price div,.cart .total .price div
{
  display: inline-block;
  font-size: 40px;
}
.calendar .total .duration div,.cart .total .duration div
{
  display: inline-block;
  font-size: 34px;
  color: #d1ae68;
}

.calendar .info_2.pin
{
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 21px;
    background-image: url(assets/img/info_pin.svg);
}

.calendar .info_2.calend
{
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 21px;
    background-image: url(assets/img/calendar.svg);
}

.calendar .info_2
{
    color: #111827;
    float: left;
    width: 100%;
    font-size: 15px;
    padding-left: 32px;
    line-height: 28px;
    font-size: 20px;
    box-sizing: border-box;
}

.calendar .info_2 label
{
  font-family: 'PT Sans', sans-serif;
  float: left;
  width: 100%;
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 12px;
}

.recommendedDiscount {
  transform: translateY(-6px);
  text-decoration: line-through;
  font-size: 13px;
  div {
    text-decoration: line-through;
    font-size: 0.9em;
  }
}

.calendar .discount
{
  position: absolute;
  right: 15px;
  top: 15px;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  text-decoration: line-through;
  color: gray;
}

.discountLabel {
  padding: 0.2em 0.5em;
  background-color: black;
  color: white;
  display: inline-block;
  text-decoration: none!important;
  line-height: 1.6em;
  font-size: 1em;
  margin-left: 0.2em;

}

.calendar .price
{
  position: absolute;
  right: 15px;
  top: 22px;
  font-size: 16px;
}
.calendar .recommendedDiscountPrice
{
  position: absolute;
  right: 15px;
  top: 30px;
  font-size: 16px;
}

.calendar .price div
{
  display: inline-block;
  font-size: 28px;
}
.calendar .recommendedDiscountPrice div
{
  display: inline-block;
  font-size: 28px;
}

.calendar .calendarblock .header .info.infoflex
{
  display: flex;
  align-items: center;
  height: 62px;
  margin-top: 0px;
  color: black;
  overflow: hidden;
  line-height: 24px;
  align-items: start;
  margin-bottom: 10px;
}

.calendar .calendarblock .header .info.infoflex b
{
  width: 600px;
  line-height: 24px;
}

.noborder
{
  margin: 0px;
  padding: 0px;
  width: calc(100% - 90px);
  margin-left: 45px;
  margin-right: 45px;
}

.edit
{
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
  border: 1px solid #272727;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  color: #272727;
  text-decoration: none;
  float: right;
  font-weight: bold;
  padding-right: 14px;
  letter-spacing: 3px;
}

.edit b
{
  background-color: #3C3B3B;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url(assets/img/edit.svg);
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 14px;
}

.edit_2
{
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
  border: 1px solid #272727;
  background-color: #272727;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  text-decoration: none;
  float: right;
  font-weight: bold;
  padding-right: 15px;
  padding-left: 15px;
  letter-spacing: 3px;
}

.inputs h2
{
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'PT Sans', sans-serif;
  font-size: 25px;
  letter-spacing: 9.8px;
}



.cart
{
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 130px;
  border-top: 1px solid #D1AE68;
  box-sizing: border-box;
  z-index: 9;
  padding-left: 15px;
  color:#000;
  background-color:rgba(0,0,0,0.7);
  backdrop-filter: blur(10px);
  display: block;
  transition: 0.3s transform ease-in-out,0.15s opacity ease-out;
  opacity: 0;
  transform: translateY(100%);

  &.active {
    opacity: 1;
    transform: translateY(0%);
    transition:0.15s opacity ease-out, 0.3s transform ease-in-out;
  }
}
.mobilecart {
  display: flex;
  opacity: 0;
}

.cart .cartinner
{
  box-sizing: border-box;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: calc(100% - 600px);
  overflow: auto;
  white-space: nowrap;
  position: relative;
  float: left;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.cart .calendar
{
  margin: 0px;
  width: 100%;
  float: left;
}

.cart .calendar .calendarblock
{
  width: 100%;
  white-space: normal;
}

.cart .calendar .calendarblock .header .info.infoflex b
{
  width: 100px;
  font-size: 16px;
}

.cart .carttitle
{
  float: left;
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0px;
  width: 100%;
  color: #E8E8E8;
  text-transform: uppercase;
  .boxedx {
    vertical-align: middle;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.cart .calendar .calendarblock .header .close
{
  width: 18px;
  height: 18px;
    background-size: 13px;
}

.cart .button
{
  position: absolute;
  top: 40px;
  right: 15px;
  height: 48px;
  line-height: 46px;
  color: #272727;
  background-color: #fff;
  border: 1px solid #fff;
  font-family: 'PT Sans', sans-serif;
  letter-spacing: 3px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  float: right;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  transition: 0.2s all;
  &:hover {
    background-color: #3C3B3B;
    color: #fff;
  }
  &.disabled {
    background-color: #e7e7e7;
    border: 1px solid #e7e7e7;
    color: #fff;
    &:hover {
      box-shadow: none;
    }

  }
}

.cart .total
{
  position: absolute;
  border-top: 0px solid #000;
  width: 240px;
  font-size: 15px;
  padding-top: 0px;
  height: 80px;
  top: 5px;
  right: 210px;
  top:19px;
  color:white;
  text-shadow: 1px 1px 0px #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  &:before {
    content: " ";
    display: block;
    width: 1px;
    height: 110%;
    background: rgba(0, 0, 0, 0.2);
    transform: translateY(10px);
    /* box-shadow: 0 0 0px 1px black; */
  }
}

.cart_item .calendar
{
  width: 300px;
}

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: scale3d(0,0,0);
    transform: none;
  }
}

.cart .cartblock
{
  opacity: 0;
  animation: show 400ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  display: inline-block;
  overflow: hidden;
  margin-right: 5px;
  margin-inline: 5px;
  padding: 0px;
  padding-bottom: 8px;
  box-shadow: 0 15px 0px var(--active-cart-color, rgba(209, 174, 104,var(--opacity, 0.3)));
  height: 105px;
  white-space: nowrap;
  transition: filter 0.2s;
  filter: brightness(0.7);

  .cart_item {
    //float: left;
    opacity: 0;
    animation: show 400ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
    display: inline-block;
    max-width: 300px;
    margin-right: 10px;
    background-color: #fff;
    transition: width 0.35s ease-in-out 0.12s, margin 0.1s ease-out;
    overflow: hidden;
    .header {
      .img {
        filter: saturate(0.1);
      }
      .hasDepsIssue {
        color: red;
        &::before {
          content: '⚠️';
        }
      }
    }

    &.remove-from-cart {
      opacity: 1;
      animation: hide 200ms 100ms ease-in forwards;
    }

    &:last-of-type {
      margin-right: 0px!important;
    }
  }

  &.collapse-items, &.collapse-attendee-items {
    .cart_item {
      width: 78px;
      transition: width 0.35s ease-in-out 0.12s,margin 0.1s ease-out;
      &:hover {
        width: 300px;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }
    &.stack-collapsed-items {
      .cart_item {
        width: 30px !important;
        margin-right: 0 !important;
        box-shadow: -2px 0px 5px -3px;
        &:last-child {
          margin-right: 0 !important;
        }

        &:hover {
          margin-right: 0 !important;
          width: 300px !important;
          filter: brightness(1);
        }
      }
    }
  }
  &:nth-child(1) {
    --active-cart-color: rgba(121, 174, 214, var(--opacity, 0.3));
  }
  &:nth-child(2) {
    --active-cart-color: rgba(253, 208, 81, var(--opacity, 0.3));
  }
  &:nth-child(3) {
    --active-cart-color: rgba(248, 195, 178, var(--opacity, 0.3));
  }
  &:nth-child(4) {
    --active-cart-color: rgba(205, 227, 213, var(--opacity, 0.3));
  }
  &.active,&:hover {
    --opacity: 1;
    filter: brightness(1);
    box-shadow: 0 15px 0px var(--active-cart-color, rgba(209, 174, 104, var(--opacity, 0.3)));
    color:#fff;
    .cart_item {
      width: 300px !important;
      margin-right: 10px!important;
      &:last-child {
        width: 300px !important;
        margin-right: 0 !important;
      }
    }
    .carttitle {
      font-weight: 600;
      color:var(--active-cart-color, rgba(209, 174, 104, var(--opacity, 0.3)));
    }
    &.collapse-attendee-items {
      .cart_item {
        width: 78px!important;
        transition: width 0.35s ease-in-out 0.12s, margin 0.1s ease-out;
        &:last-child {
          width: 300px !important;
          margin-right:0!important;
        }
        &:hover {
          width: 300px !important;
        }
      }
      &.stack-collapsed-items {
        .cart_item {
          width: 30px !important;
          margin-right: 10px !important;
          &:last-child {
            width: 300px !important;
            margin-right: 0 !important;
          }
          &:hover {
            width: 300px !important;
            margin-right: 0 !important;
          }
        }
      }
    }
  }
  &.add-person {
    background: transparent;
    height: 100%;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20ch;
  }
  &.totals {
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    filter: none;
    box-shadow: none;
  }
}

.cart .gray
{
  white-space: normal;
  float: left;
  border: 1px dashed #999999;
  border-radius: 5px;
  padding: 18px;
  box-sizing: border-box;
  height: 78px;
  color: #272727;
  font-size: 15px;
  width: 170px;
  font-family: 'PT Sans', sans-serif;
  background-color: #F5F5F5;
}

.cart .button_plus
{
  color:#000;
  position: relative;
  float: left;
  line-height: 46px;
  font-family: 'PT Sans', sans-serif;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bold;
  float: left;
  background-repeat: no-repeat;
  background-position: left  center;
  background-size: 21px;
  background-image: url(assets/img/plus_neg.svg);

  padding-left: 28px;
  box-sizing: border-box;
  margin-left: 15px;
  color: white;
  text-shadow: 1px 1px 0px #000;


  & + span {
    transition: all 0.2s;
    opacity: 1;
    color: #ddd;
    font-size: 0.9em;
    font-family: 'PT Sans', sans-serif;
    padding: 0px 15px;
  }
}

.inner_noscroll
{
  float: left;
  width: 100%;
}

.inner_noscroll .item
{
  float: left;
  display: block;
}

.inner
{
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-snap-type: x proximity;
}

.inner::-webkit-scrollbar,.cartinner::-webkit-scrollbar
{
  display: none;
}

.searchtags
{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  padding-left: 48px;
}

.searchtag
{
  float: left;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
  border-radius: 12px;
  border: 1px solid #272727;
  margin-top: 12px;
  font-size: 13px;
  background-color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}

.searchtag b
{
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -3px;
  margin-left: 4px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 13px;
  background-image: url(assets/img/close.svg);
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
}

.searchoptions
{
  width: calc(100% - 2px);
  position: absolute;
  top: 55px;
  left: 0px;
  border: 2px solid #D1AE68;
  background-color: #fff;
  z-index: 2;
  box-sizing: border-box;
  display: none;
}

.searchoptions a
{
  float: left;
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  line-height: 36px;
  font-family: 'PT Sans', sans-serif;
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #E8E8E8;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.searchoptions a:hover
{
  font-weight: bold;
}

.cart .total b
{
  display: none;
}

.cart .carttitlemob
{
  float: left;
  font-size: 24px;
  letter-spacing: 3px;
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  margin-top: 15px;
  height: 42px;
  line-height: 42px;
  display: none;
  color:white;
  text-shadow: 1px 1px 0px #000;
}


.quick {
  border:0;
  padding: 0;
  .quicksummary {
    position: relative!important;
    left: 0!important;
    top: 0!important;
    min-height: 10px;
  }

  display: flex;
  //display: none!important;
  flex-direction: column;
  width: auto;
  clear: both;
  padding: 0.5em;
  border: 2px solid #d9bb93;
  flex: 0 1 33%;

  .person {

    //border-bottom: 1px solid #ddd;
    .head {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      font-weight: bold;
    }
  }

  // .service {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  //   justify-content: space-between;
  //   line-height: 48px;
  //   align-content: center;
  //   min-height: 48px;
  //   height: auto;

  //   &>div {
  //     display: flex;

  //     &:nth-child(1),
  //     &:nth-child(2) {
  //       flex: 2;
  //     }

  //     &:nth-child(3),
  //     &:nth-child(4) {
  //       flex: 1;
  //       justify-content: end;
  //     }

  //     img {
  //       width: 48px;
  //       height: 48px;
  //       object-fit: cover;
  //       margin-right: 6px;
  //     }
  //   }
  // }

  .line {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    margin-block: 4px;

    >div {
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 4;

        span {
          letter-spacing: 0px;
          line-height: 1.8em;
          padding-inline: 0;
          text-align: left;
          height: auto;
        }
      }

    }

    b,
    div,
    span {
      height: 32px;
      line-height: 32px;
      font-size: 15px;
      padding-inline: 15px;
      margin: 0;
      font-weight: 300;

      &:last-of-type {
        text-align: right;
      }

      img {
        vertical-align: middle;
        margin-block: 0;
        max-width: 80px;
      }
    }
  }
}

.cc-window {
  font-family: 'Playfair Display', serif!important;
  .cc-btn {
    padding: 0;
    height: 48px;
    line-height: 46px;
    color: #272727!important;
    background-color: #fff!important;
    border: 1px solid #fff!important;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 3px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    float: right;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    transition: 0.2s all;
    text-transform: uppercase;

    &:hover {
      background-color: #3C3B3B!important;
      color: #fff!important;
    }

    &.disabled {
      background-color: #e7e7e7;
      border: 1px solid #e7e7e7;
      color: #fff;

      &:hover {
        box-shadow: none;
      }

    }
  }
}

@media screen and (max-width: 1440px) {

  h1 {
    font-size: 38px;
  }

  .quick {
    summary {
      font-size: 1em;
    }

    .line {
      flex-wrap: wrap;
      min-height: 38px;

      >div {
        //flex: auto !important;
      }

      b,
      div,
      span {
        padding-inline: 5px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .quick {
    .service {
      flex-wrap: wrap;
      height: auto;
      line-height: 2em;
      padding-block: 5px;

      &>div {
        flex-basis: auto !important;

        &:nth-child(1) {
          order: 2;
          flex: 2;
          min-width: 50%;
        }

        &:nth-child(2) {
          order: 0;
          flex: 2;
          line-height: 48px;
        }

        &:nth-child(3) {
          order: 1;
          flex: 1;
        }

        &:nth-child(4) {
          order: 3;
          flex: 1;
        }
      }
    }
  }
}


#itemToBeAdded{
  position: absolute;
  top: -100px;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
:root {
  --moveToCartX: 50%;
  --moveToCartY: 100px;
}

.cart .carttitlemob b
{
  cursor: pointer;
  height: 42px;
  width: 42px;
  float: right;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url(assets/img/close.svg);
}
.drop-into-cart {
  position: fixed;
  top: var(--moveToCartY, 100px);
  left: var(--moveToCartX, 50%);
  animation: moveToCart 1.2s forwards;
  animation-timing-function: cubic-bezier(0.468, -0.02, 0.65, 1.55);
}

@keyframes moveToCart {
  from {
    top: var(--moveToCartY, 100px);
    left: var(--moveToCartX, 50%);
    transform: scale(1);
    //scale: 1;
  }
  to {
    top: 100%;
    left:25%;
    transform: scale(0);
    //scale: 0;
  }
}

@media screen and (max-width: 1440px)
{
  .content.nopadding .saloni
  {
    padding-top: 40px;
    margin-top: 30px;
  }

  .content .textblock
  {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 21px;
  }

  .content .textblock ul
  {
    margin-top: 15px;
    padding-left: 0px;
  }

  .galerija h2
  {
    font-size: 20px;
  }

  .galerija .item
  {
    display: inline-block;
    white-space: nowrap;
    float: none;
      margin: 0px;
      margin-right: 10px;
      width: calc(100% / 2.5);
  }

  .galerija .item
  {
      margin-right: 0px !important;
  }

  .saloni
  {
    margin-top: 0px;
    padding-top: 0px;
  }

  .saloni .buttons
  {
      float: left;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      .button_text {
        display: none;
      }
  }

  .saloni h1
  {
    font-size: 38px;
  }

  .saloni .map
  {
    aspect-ratio: 1;
    margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);
  }

  .saloni .bg
  {
    height: calc(50% + 90px);
    top: -30px;
  }

  .saloni .map img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .saloni .block
  {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding: 15px;
  }

  .saloni .warning
  {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .saloni .block .left .info
  {
    box-sizing: border-box;
  }

  .saloni .agenda
  {
    width: 100%;
    margin-top: 20px;
  }

  .saloni .block .left,.saloni .block .right
  {
      width: 100%;
  }

  .saloni .block .right
  {
      margin-top: 20px;
  }

  .kategorija
  {
      padding-left: 5px;
      padding-right: 5px;
  }

  .kategorija .item img
  {
      border-right: 10px solid #EEDFC1;
      border-bottom: 10px solid #EEDFC1;
      width: calc(100% - 10px);
  }

  .kategorija h2, .kategorija h1
  {
    font-size: 38px;
    margin-top: 30px;
  }

  .kategorija .item
  {
    float: left;
      margin: 10px;
      width: calc((100% / 2) - 20px);
  }

  .zaupamo
  {
    overflow: hidden;
  }

  .zaupamo h2
  {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .inner
  {
    overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      width: 100%;
  }

  .zaupamo .inner .item
  {
    margin-left: 0px;
    margin-right: 30px;
    white-space: normal;
    width: 120px;
  }

  .zaupamo .inner .item:last-of-type
  {
    margin: 0px;
  }

  .chip .inner a
  {
    margin-left: 0px;
    margin-right: 5px;
    white-space: normal;
    margin-bottom: 10px;
  }

  .chip .inner a:last-of-type
  {
    margin-right: 0px;
  }

  .zaupamo .inner .item:last-of-type
  {
    margin: 0px;
  }

  header
  {
    display: block;
    text-align: center;
    height: 80px;
    line-height: 80px;
  }

  header .logo
  {
    display: inline-block;
    position: relative;
    float: none;
    margin-left: 0px;
  }

  header .logo img
  {
    height: 90px;
    margin-top: 12px;
  }

  header .menu
  {
    display: none;
  }

  header .phone
  {
    display: none;
  }

  header .search
  {
    display: none;
  }

  header .user
  {
    //float: right;
    margin-top: 21px;
    position: absolute;
    right:15px;
    top:0;
    opacity: 0;
    transition: all 0.1s;

    &.visible {
      opacity: 1;
    }
  }
  header .me {
    position: absolute;
    top: 0;
    right:15px;
    .logged-in-user {
      margin-top:21px;
    }
    opacity: 0;
    transition: all 0.1s;
    &.visible {
      opacity: 1;
    }
  }

  // fake rotate animation
  @keyframes rotate {
    0% {
      box-shadow: 0 0px 0px #000;
    }
    1% {
      box-shadow: 0 -1px 0px #000;
    }
    25% {
      box-shadow: 1px 0px 0px #000;
    }
    50% {
      box-shadow: 0 1px 0px #000;
    }
    75% {
      box-shadow: -1px 0px 0px #000;
    }
    99% {
      box-shadow: 0 -1px 0px #000;
    }
    100% {
      box-shadow: 0 0px 0px #000;
    }
  }

  header .mobmenu
  {
    display: block;
    margin-top: 21px;
    position: absolute;
    top: 0;
    border: 1px solid transparent;
    border-radius:50%;
    animation-name: rotate;
    animation-duration: 1s;
    animation-delay: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }

  .bigblock
  {
    margin-top: 0px;
    height: auto;
    flex-direction: column;


    .down
    {
      display: none;
    }

    .text h2
    {
      font-size: 38px;
      line-height: 48px;
    }

    .vertical
    {
      left: 10px;
      top: 40px;
    }

    .text
    {
        position: relative;
        float: left;
        width: calc(100% - 70px);
        padding: 0px;
        margin-left: 35px;
        margin-top: 60px;
        margin-bottom: 20px;
        aspect-ratio: initial;
        // font-size: 24px;
        font-size: 19px;
    }

    img
    {
      aspect-ratio: initial;
        position: relative;
        float: right;
        width: calc(100% - 35px);
        object-fit: cover;
        max-height: 40vh;
    }

    .shop {
      display: none;
    }
    .mobileshop {
      display: inline-block!important;
      text-align: left;
      position:relative;
    }

    .price {
      i {
        font-size: 48px;
      }
    }

  }

  .midblock .shop {
    display: none;
  }
  .midblock .mobileshop {
    display: inline-block!important;
    text-align: left;
    position:relative;
  }



  .aktualno
  {
    padding-left: 0px;
    padding-right: 0px;
  }

  .aktualno h2
  {
      font-size: 20px;
      margin-bottom: 15px;
  }

  .aktualno .item b
  {
    font-size: 24px;
  }

  .spacer_3,.spacer_1,.spacer_2,.spacer_4,.spacer_5,.spacer_6
  {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  .copyright
  {
    font-size: 13px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }

  .copyright span
  {
    display: block;
    text-align: center;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
  }

  .content
  {
    padding-left: 15px;
    padding-right: 15px;
  }

  .content.nopadding
  {
    padding-left: 0px;
    padding-right: 0px;
  }

  .items
  {
    width: calc(100% - 10px);
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;

    .item {
      width: calc((100% / 2) - 20px);
      padding-bottom: 76px;
      margin: 10px;
      border: 0px;
      border-bottom: 10px solid #d1ae68;

      &.blue {
        border-color: #E0F3F4;
      }

      &.yellow {
        border-color: #FEE7A9;
      }

      &.fuksia {
        border-color: #F9CED2;
      }

      .new {
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 11px;
        top: -24px;
        right: 10px;
      }

      .img {
        width: 100%;
        margin: 0px;
      }

      b {
        font-size: 20px;
        width: 100%;
        height: 5.6em;
        display: -webkit-box;
        -webkit-line-clamp: 4;
      }

      .desc {
        font-size: 12px;
        width: 100%;
        margin-left: 0px;
      }

      .bag {
        background-size: 18px;
        width: 42px;
        height: 42px;
        bottom: -10px;
        right: 0px;
      }

      .discount {
        font-size: 12px;
        bottom: 55px;
        left: 0px;
      }

      .price {
        font-size: 16px;
        left: 0px;

        span {
          font-size: 40px;
        }
      }

    }
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }



  .aktualno .item
  {
      width: calc((100% / 1) - 30px);
  }

  .midblock
  {
    display: block;
    border-left: 15px solid #E0F3F4;
    border-right: 15px solid #E0F3F4;
    border-bottom: 15px solid #E0F3F4;
    border-top: 0px;
    width: calc(100% - 30px);
    padding-bottom: 40px;


    &.full
    {
      border-top: 15px solid #E0F3F4;
      border-left: 15px solid #E0F3F4;
      padding: 25px;
      width: 100%;
      box-sizing: border-box;
    }

    &.full .text
    {
    padding: 0px;
    }

     img
    {
      width: 100%;
   }

     .text span
    {
    margin-top: 20px;
   }

     .vertical
    {
    left: 10px;
    }

     .text
    {
      float: left;
      width: 100%;
      padding: 0px;
      padding-top: 20px;
      padding-left: 35px;
      padding-right: 20px;
      flex: none;
      position: relative;
    }

    .price {
      i {
        font-size: 48px;
      }
    }
  }


  footer .menu a
  {
    float: left;
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    margin: 0px;
  }

  footer .phone
  {
    font-size: 32px;
  }

  .profile
  {
    margin-top: 0px;
    padding-top: 60px;
  }

  .profile h1
  {
      font-size: 38px;
  }

  .profile b
  {
    box-sizing: border-box;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile .bio
  {
    font-size: 20px;
    box-sizing: border-box;
      position: relative;
      z-index: 1;
      float: left;
      width: 100%;
      padding-left: 15px;
      paddiing-right: 15px;
  }

  .clanek
  {
    margin-top: 0px;
    padding-top: 60px;

    h2,
    h1 {
      font-size: 34px;
      margin-top: 10px;
    }

    .bigimage {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 10px);
      margin-bottom: 0px;
    }

    .smallimage {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 10px);
    }

    p {
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);
    }

    h3 {
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);

    }

    div.p {
      float: left;
      margin-inline: 15px;
      width: calc(100% - 30px);
      z-index: 1;
      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;

      p {
        font-size: 18px;
        margin: 0;
        width: auto;
      }
    }

  }

  .slider
  {
    height: auto;
  }

  .slider h2, .slider h1
  {
    font-size: 38px;
  }

  .slider .block
  {
    padding-left: 0px;
    padding-right: 0px;
  }

  .slider .block .left,.slider .block .right
  {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .slider .block .right
  {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    box-sizing: border-box;
  }

  .slider .nav
  {
    margin-bottom: 20px;
  }

  .slider .block_text
  {
      position: relative;
      width: 100%;
      top: 0px;
      left: 0px;
      padding-left: 35px;
      margin-top: 30px;
      margin-bottom: 20px;
      float: left;
      box-sizing: border-box;

      b {
        font-size: 38px;
      }
  }

  .slider .block_image
  {
      position: relative;
      float: left;
      width: 100%;
      height: auto;
      top: 0px;

      img, video {
        height: auto;
        position: relative;
        margin-left: 35px;
        margin-top: 35px;
        width: calc(100% - 35px);
        float: left;
        aspect-ratio: 16/9;
        object-fit: cover;
      }
  }

  .slider .nav.main
  {
    position: relative;
  }

  .code,.prijava,.smallblock
  {
    margin-top: 0px;
  }

  .code,.prijava
  {
    padding-top: 60px;
  }

  .code h1,.prijava h1
  {
    font-size: 34px;
  }

  .code span,.prijava span
  {
    margin-top: 30px;
  }

  .smallblock .info
  {
      margin-left: 15px;
      width: calc(100% - 15px);
      margin-top: 30px;
  }

  .smallblock .info .name
  {
    float: left;
    width: 100%;
    margin: 0px;
  }

  .saloni h1.left
  {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 30px;
  }

  .saloni .map.fullmap
  {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }


  .saloni .button_1
  {
    float: right;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  .saloni .button_2
  {
    box-sizing: border-box;
    float: left;
    text-align: center;
    width: 100%;
    transition: all 0.15s;
  }

  .saloni .button_text
  {
    box-sizing: border-box;
    float: left;
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    line-height: 21px;
  }

  .calendar
  {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
  }

  .calendar .calendarblock .header .info.infoflex b
  {
    width: 120px;
    font-size: 16px;
  }

  .calendar .calendarblock .header .info.infoflex_2
  {
    //display: flex;
    align-items: center;
    //height: 36px;
    margin-top: 0px;
  }

  .calendar .calendarblock .header .info.infoflex_2 b
  {
    width: 120px;
    font-size: 16px;
  }

  .calendar .calendarblock .header .info.infoflex_2 i
  {
    width: 120px;
    font-size: 16px;
    position: absolute;
    bottom: 5px;
  }

  .calendar .calendarblock .header .drop
  {
      margin-right: 20px;
      margin-left: 10px;
  }

  .items .item .vertical
  {
      writing-mode: initial;
      text-orientation: initial;
      transform: scale(1);
      letter-spacing: 3px;
      position: relative;
  }

  .calendar .next,.calendar .prev
  {
    display: none;
  }

  .calendar .coupon
  {
    float: left;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    min-height: 128px;
    height: auto;
  }

  .calendar .coupon.first
  {
    margin-bottom: 30px;
  }

  .calendarblock.white div.calend
  {
    width: calc(100% - 40px);
    box-sizing: border-box;
  }

  .calendar .calendarblock.dark
  {
    min-height: 100px;
    height: auto;
  }

  .calendar .calendarblock.dark div.pin,.calendar .calendarblock.dark div.calend
  {
    width: calc(100% - 40px);
    box-sizing: border-box;
  }

  .chip.left
  {
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
  }

  .calendar h1
  {
    font-size: 38px;
  }

  .calendarblock.dark i, .calendarblock.white i
  {
    top: 10px;
    right: 10px;
      margin-top: 0px;
      margin-right: 0px;
  }

  .prijava .inputs.left
  {
    float: left;
    width: 100%;
    max-width: 100%;
    margin: 0px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }

  .edit
  {
    width: 100%;
  }

  .prijava .day
  {
    width: 100%;
    margin-right: 0px;
  }

  .prijava .month
  {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .prijava .year
  {
    width: 100%;
    margin-left: 0px;
  }

  .prijava .inputs .radio
  {
    float: left;
    width: calc(100% - 20px);
    text-align: left;
    margin-bottom: 15px;
  }

  .cart
  {
    height: auto;
    max-height: 75vh;
    display: block;
    padding-right: 10px;
    transition: 0.3s transform ease-in-out, 0.15s opacity ease-out;
    opacity: 0;
    transform: translateY(100%);

    &.active {
      display: inherit;
      opacity: 0;
      transform: translateY(100%);
      z-index: 1002;
    }

    &.expand-mobile {
      display: block;
      opacity: 1;
      transform: translateY(0%);
      transition: 0.15s opacity ease-out, 0.3s transform ease-in-out;
    }

    .cartinner {
      float: left;
      width: 100%;
      overflow: hidden;
      white-space: normal;
      max-width: 100%;
      overflow-y: scroll;
      max-height: 35vh;
    }


    .cartblock {
      float: left;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      box-shadow: none;
      filter: brightness(0.9);
      &.add-person {
        display: none;
      }

      &.totals {
        background-color: transparent;
        position: relative;
        width: 100%;
      }
      &.active,&:hover {
        box-shadow: none;
        &.collapse-attendee-items,
        &.collapse-items,
        &.stack-collapsed-items {
          .cart_item {
            width: 100% !important;
            &:last-child{
              width: 100% !important;
            }
          }
        }
        .cart_item {
          width: 100% !important;
          &:last-child {
            width: 100% !important;
          }
        }
      }
      &.collapse-items {
        &.collapse-attendee-items {
          &.stack-collapsed-items {
            .cart_item {
              width: 100% !important;

              &:last-child {
                width: 100% !important;
              }
            }
          }
          .cart_item {
            width: 100% !important;

            &:last-child {
              width: 100% !important;
            }
          }
        }
        &.stack-collapsed-items {
          .cart_item {
            width: 100% !important;

            &:last-child {
              width: 100% !important;
            }
          }
        }
        .cart_item {
          width: 100% !important;

          &:last-child {
            width: 100% !important;
          }
        }
      }

      .cart_item {
        max-width: 100%;
        width: 100%!important;
        margin-bottom: 10px;
      }
    }


    .gray {
      width: 100%;
      float: left;
      height: auto;
    }

    .button_plus {
      margin-top: 0px;
      margin-left: 0px;
    }

    .button {
      position: relative;
      float: right;
      top: 0px;
      margin-right: 15px;
      width: 100%;
      height: 64px;
      line-height: 64px;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .total {
      border-top: 1px solid #272727;
      position: relative;
      float: right;
      height: none;
      top: 0px;
      right: 15px;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }


  .cart_item .calendar
  {
    width: 100%;
  }


  .cart .calendar .total .price
  {
        top: 0px;
    }

    .cart .calendar .total .discount
    {
        top: 60px;
  }

  .cart .total b
  {
    display: block;
    position: absolute;
    left: 15px;
    top: 20px;
  }

  .cart .carttitlemob
  {
    display: block;
    b {
      filter: invert(1);
    }
  }

  .galerija .next,.galerija .prev
  {
    display: none;
  }

  .cart .total .duration {
    top: 5px;
    div {
      font-size: 29px;
    }
  }

  .cart .total .price
  {
    top: 0px;
    div {
      font-size: 34px;
    }
  }

  .cart .total .discount
  {
    top: 54px;
  }



  .mobilecart {
    display:flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    border-top: 1px solid #D1AE68;
    position: sticky;
    bottom:0px;
    transition: 0.3s transform ease-in-out, 0.15s opacity ease-out;
    opacity: 0;
    transform: translateY(200%);
    &.active {
      opacity: 1;
      transform: translateY(0%);
      z-index: 1001;
      width: 100%;
    }

    > * {
      flex: 1;
    }

    .carttitlemob {
      background-color: #fff;
      color:#000;
      height: 100%;
      text-align: center;
      height: 4em;
      line-height: 4em;
    }

    .button {
      height: 4em;
      line-height: 4em;
      text-align: center;
      background-color: #272727;
      color: #fff;
      font-family: "PT Sans", sans-serif;
      letter-spacing: 3px;
      text-transform: uppercase;
      &.disabled {
        background-color: #999;
        color: #fff;
      }
    }
  }

  .kategorija b
  {
    background-size: 20px 16px;
    padding-right: 28px;
    word-break: break-word;
    font-size: 21px;
    width: 100%;
  }

  .calendar .calendartitle b
  {
      width: 100%;
      margin-top: 10px;
  }

  .smallblock.full
  {
    height: 200px;
    line-height: 200px;
    margin-bottom: 30px;
  }

  .smallblock.full h1
  {
    font-size: 38px;
  }

  .left.forgalleries
  {
    margin-left: 0px !important;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .options div.divisor
  {
    width: 100%;
    float: left;
    background-image: url(assets/img/fi_chevron-down.svg);
    height: 36px;
    background-size: 28px;
    margin: 0px;
  }

  .galerija.full .inner_noscroll .item
  {
      width: calc((100% / 3) - 10px);
      margin: 5px;
  }

  .galerija.full
  {
    padding-left: 10px;
    padding-right: 10px;
  }

  .galerija.full .item
  {
      width: calc((100% / 2.5) - 10px);
      margin: 0px;
  }
}

@media screen and (max-width: 650px) {
  .calendar {
    .calendarblock {
      .app-slots {
        grid-template-columns: repeat(4, 1fr);

        .line:nth-child(8n+1),.line:nth-child(8n+3),.line:nth-child(8n+6), .line:nth-child(8n+8) {
          background-color: #fff;
        }
        .line:nth-child(8n+2),.line:nth-child(8n+4),.line:nth-child(8n+5),.line:nth-child(8n+7) {
          background-color: #f0f0f0;
        }
      }
    }
  }
}


@media screen and (max-width: 450px)
{
  .calendar {
    .calendarblock {
      .app-slots {
        grid-template-columns: repeat(2, 1fr);

        .line:nth-child(4n+1),.line:nth-child(4n+4) {
          background-color: #fff;
        }
        .line:nth-child(4n+2),.line:nth-child(4n+3) {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .items {
    .item {
      min-width: unset;
    }
  }
  .pick_category, .aktualno {
    .buttons {
      .button {
        line-height: 28px;
        padding-block: 10px;
      }
    }
  }
  .calendar {
    .calendarblock {
      .line {
        flex-basis: 49.97%;
        .other-combinations {
          .col--main {
            span {
              display: inline;
            }
            img {
              display: inline;
            }
          }
        }
        &.collapsed + .expand {
          color:#272727;
        }
      }
    }
  }
}

.recommended-modal {
  --width:800px;
  --height:500px;
}

html.plt-mobile ion-app {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.preformatted-text {
  white-space: pre-line;
}

.uppercase {
  text-transform: uppercase;
}

.canceled {
  text-decoration: line-through;
}